import React from "react"
import { Link } from "gatsby"
import {RiArrowLeftSLine, RiBugLine, RiSkullLine} from "react-icons/ri"

import SEO from "../components/seo"
import Layout from "../components/layout"

const NotFound = () => (
  <Layout className="not-found-page">
    <SEO 
      title="Page Not Found" 
      description="The page you're looking for doesn't exist or has been moved. Explore our HR technology resources to find what you need."
      keywords={["404", "page not found", "HR technology resources"]}
    />
    <div className="wrapper" style={{
      textAlign: "center"
    }}>
      <header>
        <RiSkullLine style={{
          fontSize: "64px",
          color: "var(--primary-color)"
        }}/>
        <h1 style={{
          fontSize: "2.25rem",
          marginBottom: "1rem"
        }}>Oops we did not expect that to happen</h1>
        <p style={{
          fontSize: "1rem",
          marginBottom: "2rem"
        }}>Have you wondered into the unknow. Let us help you, Please take a look at below options</p>
      </header>
      <Link to="/" className="button" style={{ fontSize: "1rem" }}><RiArrowLeftSLine className="icon -left"/>Back to Homepage</Link>
      <Link to="/contact" className="button -outline" style={{ fontSize: "1rem" }}>Report this <RiBugLine className="icon -right"/></Link>
    </div>
  </Layout>
)

export default NotFound